import '../css/app.css'
import './bootstrap'

import { createInertiaApp } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { createPinia } from 'pinia'
import { createApp, h } from 'vue'
import { ZiggyVue } from '../../vendor/tightenco/ziggy'

const appName = import.meta.env.VITE_APP_NAME || 'Laravel'

import Vapor from 'laravel-vapor'
import { sentryPlugin } from './util/sentry'
window.Vapor = Vapor
window.Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL)

createInertiaApp({
  title: (title) => (title === 'Home' ? `${appName}` : `${title} - ${appName}`),
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob('./Pages/**/*.vue')
    ),
  setup({ el, app: inertiaApp, props, plugin }) {
    createApp({ render: () => h(inertiaApp, props) })
      .use(plugin)
      .use(sentryPlugin)
      .use(createPinia())
      .use(ZiggyVue)
      .mixin({ methods: { asset: window.Vapor.asset } })
      .mount(el)
  },
})

InertiaProgress.init({
  color: '#818cf8',
})
